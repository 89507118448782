import React from "react"
import SEO from "../components/seo"

import { CardLeft, CardRight, Line } from "../components/elements"
import Layout from "../components/layout"
import {
  HeaderPage,
  IntroSection,
  Services,
  ProductDetails,
  ReasonSection,
  ServiceDetail,
} from "../components/structure"

import headerIcon from "../images/shop/headerIcon.svg"
import payment from "../images/shop/payment.svg"
import pos from "../images/shop/pos.svg"
import shopify from "../images/shop/shopify.svg"
import store from "../images/shop/store.svg"

export default function EcommerceDevelopment() {
  return (
    <Layout>
      <SEO title="eCommerce Development" />
      <HeaderPage
        capitle
        src={headerIcon}
        color={props => props.theme.procolors.shopDark}
        bgColor={props => props.theme.procolors.shopLight}
        title="A seamless online shopping platform for your business."
        catchy="eCommerce Development"
        tagline="Drive business growth with a convenient online store your customers will love."
      />
      <Line />
      <ServiceDetail>
        <IntroSection
          capitle
          title="eCommerce Development"
          details="We provide several eCommerce services that help your business to reach a national or even global audience. Our custom online stores are designed to be convenient, appealing and informative. By utilising the powers of eCommerce, we will drive more traffic to your website and stimulate growth in your business."
          color={props => props.theme.procolors.shopDark}
        />
        <ProductDetails
          to="/contact/"
          title="The eCommerce development services that we provide include:"
          color={props => props.theme.procolors.shopDark}
        >
          <CardLeft
            alt="Custom online stores"
            title="Custom online stores"
            detail="Fit your website with an efficient and fool-proof online shopping portal that will enable your customers to access your goods and services from anywhere and anytime."
            src={store}
          />
          <CardRight
            alt="Shopify stores"
            title="Shopify stores"
            detail="Make your business even more accessible to your customers with your very own Shopify store and watch your brand prosper."
            src={shopify}
          />
          <CardLeft
            alt="Point of sale integrations"
            title="Point of sale integrations"
            detail="We take care of all point of sale integrations, leaving you with the time you need to focus on your business."
            src={pos}
          />
          <CardRight
            alt="Online payment integrations"
            title="Online payment integrations"
            detail="With our online payment gateways, your eCommerce platform will work seamlessly for your customers."
            src={payment}
          />
        </ProductDetails>
        <ReasonSection
          borderColor={props => props.theme.procolors.shopLight}
          title="Why Do You Need eCommerce Development?"
          details="Even if you have a brick and mortar store, there are several reasons why your business could benefit from eCommerce development. It typically costs less to run an online store than a physical store, considering you’ll save money on staff and rent. Digital advertising for your online store is also more cost-effective than traditional advertising."
          details1="eCommerce development allows you to gather important information about your customers using web analytics, which you can then use to increase your sales further. Additionally, eCommerce allows you to defy geography and reach customers all over the country or the world, rather than just a local audience. Ultimately, eCommerce will contribute to driving more traffic to your website and growing your business."
        />
      </ServiceDetail>
      <Line />
      <Services />
    </Layout>
  )
}
